<template>

    <div>
        <div class="flex mb-2 relative">

            <loader :loading="loadingStatus" />

            

            <div class="flex-1 w-full overflow-hidden text-center" v-if="!(isMobile && i === 'total')"
                v-for="(el, i, index) in pipelineStatus" :key="i">
                <div class="text-font-gray font-bold"
                     :class="isMobile ? 'text-font-gray text-xxs':'text-xl'">{{ el.name }}</div>
                <div class=" font-bold" :class="{'text-3xl': !isMobile && i != 'Total', 'text-4xl': !isMobile && i == 'Total'}">
                    {{ el.value | reduceBigNumbers() }}
                </div>
                <div v-if="el.percent != null" class="text-success font-semibold "
                     :class="isMobile ? 'text-xs' : 'text-lg '">
                    {{ el.percent  | numberFormat(2) }}%
                </div>
            </div>

        </div>
        <div class="h-auto py-4 px-4" v-if="isMobile">
            <div class="h-auto flex flex-row justify-between items-center border-b border-font-gray">
                <span class="text-xs font-semibold text-font-gray">Totals</span>
                <span class="text-white font-semibold">
                    {{ pipelineStatus.total.value | reduceBigNumbers() }}
                </span>
            </div>
        </div>
    </div>

</template>

<script>
    import { state } from '@/store';

    export default {

        props: ['isMobile'],
        data() {
            return {
                pipelineStatus: {
                    won: {
                        name: 'Win',
                        value: 0,
                        percent: 0
                    },
                    ongoing: {
                        name: 'Ongoing',
                        value: 0,
                        percent: 0
                    },
                    lost: {
                        name: 'Lost',
                        value: 0,
                        percent: 0
                    },
                    total: {
                        name: 'Total',
                        value: 0,
                        percent: null
                    },
                },
                loadingStatus: false
            }
        },
        methods: {
            preload() {
                this.loadingStatus = true;
            },
            getPipelineStatus() {

                // if(state.rolSelected.role_type_link != 'presidencia'){
                
                    this.axios.get('pipeline/status', {params: this.params}).then(response => {
                        this.pipelineStatus.won.value = response.data.data.won
                        this.pipelineStatus.won.percent = response.data.data.percentage_won
                        this.pipelineStatus.ongoing.value = response.data.data.ongoing
                        this.pipelineStatus.ongoing.percent = response.data.data.percentage_ongoing
                        this.pipelineStatus.lost.value = response.data.data.lost
                        this.pipelineStatus.lost.percent = response.data.data.percentage_lost
                        this.pipelineStatus.total.value = response.data.data.total
                    })

                // }

                this.loadingStatus = false;

            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{sectorAmbiciona: this.$route.params.catId}}
                }
                return params
            }
        },
        watch: {
            params() { this.getPipelineStatus() }
        },
        mounted() {
            this.preload();
            if(this.params) {
                this.getPipelineStatus();
            }
        }
    }

</script>